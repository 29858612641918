<template>
  <div class="columns is-marginless is-flex is-centered pt-6"></div>

  <section class="section">
    <div class="container">
      <div class="columns is-multiline is-centered">
        <div class="column is-8 is-5-desktop">
          <div class="box p-6 has-background-light has-text-centered">
            <h3 class="is-size-4 has-text-weight-bold">
              <!-- Wooops... 404   -->
              {{ $t("page_404_title") }}
            </h3>
            <span class="has-text-grey-dark">
              <!-- De pagina die je probeert te bereiken is verplaatst of bestaat
              niet. We brengen je graag terug naar een correcte pagina. -->
              {{ $t("page_404_body") }}</span
            >

            <basic-button
              class="is-primary mt-5 mb-3 is-fullwidth"
              @click="$router.push('/')"
              :content="$t('button_back')"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>